<template>
<main class="mainpage">

    <div class="fullver">
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" style="background: #fff;">
            <div class="container-fluid px-sm-5">
                <div class="d-flex justify-content-between my-3 text-bg-light navbar-mobile">
                    <div class="d-flex">
                        <a class="navbar-brand tapwillimg fw-bold pe-3 me-0" href="#">TapWill</a>
                        <div class="stats fw-semibol d-flex flex-column justify-content-start lh-1 fw-semibold ps-3">
                            <p class="my-auto navbar-brand-text">Статистика и аналитика <br> товаров <span class = 'Wildberries'> Wildberries </span> и <span class = 'Ozon'> Ozon </span> </p>
                        </div>
                    </div>
                    
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon d-flex"></span>
                    </button>
                </div>
                <div class="header_right ms-lg-auto ms-2">
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#about_us">О сервисе</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#tariff">Подписка</a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link fw-semibold" href="#faq">FAQ</a>
                        </li>
                        <li class="nav-item my-auto">
                        </li>
                        <li class="nav-item my-auto ">
                            <a class="nav-link fw-semibold d-flex" href="/auth">
                            <img src="../assets/images/main/iconamoon_enter-fill.png" alt="">
                            <span class="my-auto">Войти</span> 
                        </a>
                        </li>
                        <li class="nav-item my-auto">
                        <a class="nav-link btn-gradient d-flex" href="/reg">Зарегистрироваться</a> 
                        </li>
                    </ul>
                </div>
              </div>
            </div>
        </nav>
        <main class = 'main_glav'>
            <main class="main_1"> 
                <div class="maincont">
                    <p class="main_text d-flex justify-content-center fw-bold">Хотите получить эффективный учет финансов и <br> маркетинга, который дает рост прибыли на <br> маркетплейсах от 300% в год?</p>
                    <div class="under_texxt_strong d-flex flex-column lh-1 w-100 mt-3">
                      <h2 style="color: #fff; font-weight: 800; text-align: center;">Масштабируйте свой бизнес на Ozon и Wildberries <br> вместе с аналитикой от Tapwill!</h2>
                    </div>
                    <div class="under_texxt lh-1">
                      <p style="font-size: 1.5em; text-align: center; line-height: 1.2; color: #fff;">Получите 14 дней доступа и аудит вашего бренда бесплатно!</p>
                    </div>
                    <div class="regg-container d-flex justify-content-center"> 
                        <a href="/reg" class="btn-gradient" style="font-size: 1.5em;"> Попробовать бесплатно </a>
                    </div>
                </div>
            </main> 
        
            <main class = 'main_2 section-block'>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-5">
                        <div style="line-height: 1;">            
                            <p class = 'whyBuy'> Почему выбирают </p>
                            <p class = 'tap mb-5'> TapWill </p>
                            <a href="/reg" class = 'btn-gradient btn-gradient-outline' style="font-size: 1.2em;"> <span>Зарегистрироваться</span> </a>
                        </div>
                        </div>

                        <div class="col-lg-7">
                    <div class="d-flex justify-content-end">
                    <div class="points my-auto d-none d-lg-block">
                        <img src="../assets/images/main/points.png" alt="">
                    </div>
                    <div class="tables-container">
                        <div class="table-row accuracy">
                            <img src="../assets/images/main/scope.png" alt="" class="table-img">
                            <div class="table-text">
                                <p class='fs-3 fw-bold'>Точность данных</p>
                                <p class='fs-5'>Данные в отчетах сервиса полностью совпадают с данными ваших магазинов на маркетплейсах</p>
                            </div>
                        </div>
                        <div class="table-row accuracy">
                            <img src="../assets/images/main/TV.png" alt="" class = 'table-img'>
                            <div class="table-text">
                                <p class = 'fs-3 fw-bold'>  Большой функционал </p>
                                <p class = 'fs-5'> Полный набор инструментов для внутренней аналитики для Wildberries и Ozon. Более 10 полезных отчетов с удобными настраиваемыми фильтрами и параметрами </p>
                            </div>
                        </div>
                        <div class="table-row accuracy">
                            <img src="../assets/images/main/book.png" alt="" class = 'table-img'>
                            <div class="table-text">
                                <p class = 'fs-3 fw-bold'>  Легкость освоения</p>
                                <p class = 'fs-5'> Наши отчеты просты и удобны. Все отчеты имеют подробные инструкции и видео, помогающие легко читать и использовать </p>
                            </div>
                        </div>
                        <div class="table-row accuracy">
                            <img src="../assets/images/main/people.png" alt="" class = 'table-img'>
                            <div class="table-text">
                                <p class = 'fs-3 fw-bold'>  Подходит всем</p>
                                <p class = 'fs-5'> TapWill создан селлерами для селлеров. Наш опыт в товарном бизнесе и разработке IT систем позволил создать полезный и удобный продукт, поэтому он подойдет как для новичков, так и для опытных селлеров</p>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                </div>
                
            </main>

            <main class='main_3 bg-info-subtle fw-medium' id="about_us">
                <div class="main_tapwill">
                    <p class="fw-bold">Возможности сервиса TapWill</p>
                </div>
                <div class="main_category">
                    <span class = 'btn-white-outline' @click="showService = 1" :class="{'btn-white': showService == 1}">Финансовая аналитика</span>
                    <span class = 'btn-white-outline' @click="showService = 2" :class="{'btn-white': showService == 2}">Сводка по товарам</span>
                    <span class = 'btn-white-outline' @click="showService = 3" :class="{'btn-white': showService == 3}">Рекламная аналитика</span>
                    <span class = 'btn-white-outline' @click="showService = 4" :class="{'btn-white': showService == 4}">Статистика</span>
                </div>

                <div class="main_svodka" v-if="showService == 1">
                    <div class="inst lh-1">
                        <p class='fs-4 fw-bold'>Инструменты для финансовой аналитики</p>
                        <div class="inst_punct">
                            <ul>
                                <li class='fs-5'>Отчет о прибылях и убытках (P&L)</li>
                                <li class='fs-5'>Точный расчет расходов внутри маркетплейса</li>
                                <li class='fs-5'>ABC анализ</li>
                                <li class='fs-5'>Сводка продаж</li>
                                <li class='fs-5'>Учет себестоимостей</li>
                            </ul>
                        </div>
                    </div>
                    <div class="lupa">
                        <img src="../assets/images/main/loop.png" alt="" style="object-fit: contain; max-width: 100%; height: auto;">
                    </div>
                </div>

                <div class="main_svodka" v-if="showService == 2">
                    <div class="inst lh-1">
                        <p class='fs-4 fw-bold'>Товарная аналитика</p>
                        <div class="inst_punct">
                            <ul>
                                <li class='fs-5'>Актуальные данные об остатках</li>
                                <li class='fs-5'>Аналитические расчеты по артикулам</li>
                                <li class='fs-5'>Прогноз складского дефицита</li>
                                <li class='fs-5'>География заказов и продаж</li>
                                <li class='fs-5'>Информация о статусе отправлений</li>
                            </ul>
                        </div>
                    </div>
                    <div class="lupa">
                        <img src="../assets/images/main/loop.png" alt="" style="object-fit: contain; max-width: 100%; height: auto;">
                    </div>
                </div>

                <div class="main_svodka" v-if="showService == 3">
                    <div class="inst lh-1">
                        <p class='fs-4 fw-bold'>Аналитика продвижения на МП</p>
                        <div class="inst_punct">
                            <ul>
                                <li class='fs-5'>Журнал статистики по рекламным кампаниям</li>
                                <li class='fs-5'>Статистика продвижения по артикулам</li>
                                <li class='fs-5'>Статистика расходов на рекламу по дням</li>
                                <li class='fs-5'>Аналитические инструменты (cpa, ctr, cr, cpc, cpm, ДРР, ...)</li>
                                <li class='fs-5'>Отслеживание статусов рекламных кампаний</li>
                            </ul>
                        </div>
                    </div>
                    <div class="lupa">
                        <img src="../assets/images/main/loop.png" alt="" style="object-fit: contain; max-width: 100%; height: auto;">
                    </div>
                </div>

                <div class="main_svodka" v-if="showService == 4">
                    <div class="inst lh-1">
                        <p class='fs-4 fw-bold'>Статистика магазинов</p>
                        <div class="inst_punct">
                            <ul>
                                <li class='fs-5'>Дашборд с основными показателями</li>
                                <li class='fs-5'>Отчеты по заказам и продажам</li>
                                <li class='fs-5'>Аналитические инструменты</li>
                                <li class='fs-5'>Точный расчет операционных расходов</li>
                                <li class='fs-5'>Регулярная актуализация данных</li>
                            </ul>
                        </div>
                    </div>
                    <div class="lupa">
                        <img src="../assets/images/main/loop.png" alt="" style="object-fit: contain; max-width: 100%; height: auto;">
                    </div>
                </div>
            </main>

            <div class="container">
            <main class = 'main_4'>
                
                <div class="main_4_text lh-1">
                    <p class = 'fs-2 fw-bold my-lg-5'>Собираем единые отчеты по всем вашим магазинам</p>
                        <img src="../assets/images/main/Ozon_and_WB.png" alt="">
                </div>
                <div class="we_can fs-5 mt-5 ms-lg-5">
                    <p class = 'we_can_grey fs-6'>С TapWill вы сможете:</p>
                    <div class="we_can_borders">
                        <div class="we_can_border lh-1">
                            <p>Видеть прибыль по всем магазинам и маркетплейсам в одном месте</p>
                        </div>
                        <div class="we_can_border lh-1">
                            <p>Не тратить время на сбор данных из нескольких кабинетов или маркетплейсов</p>
                        </div>
                        <div class="we_can_border lh-1">
                            <p>Добавлять любое количество магазинов</p>
                        </div>
                    </div>
                </div>
                <div class="we_can_img d-flex justify-content-center">
                    <img src="../assets/images/main/Group 132 (1).png" alt="">
                </div>

            </main>
            </div>

            <main class = 'main_5'>
                <div class="container">
                <div class="main_5_cont_text">
                    <p class = 'main_5_text fs-1 fw-bold'>Переходите из таблиц в <span class = 'fiol'>TapWill</span>  </p>
                    <p class = 'main_5_text_grey fs-4'>Перестаньте сводить цифры из разных отчетов руками в таблицах, искать ошибки и анализировать неактуальные отчеты</p>
                </div>
                <div class="main_5_borders">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="main_5_border_google fs-4 lh-1">
                                <p class = 'tap fs-4'> Эксель и Гугл-таблицы</p>
                                <div class = 'line_block'>
                                    <p class = 'line'> Вручную собираете операции из </p> <p class = 'line'> финансовых и аналитических отчетов, </p> <p class = 'line'> переносите их в таблицы, а потом долго </p> <p class = 'line'>ищете, где ошибка в формуле?</p>
                                </div>
                                <img src="../assets/images/main/excel.png" alt="">
                            </div>
                        </div>
                        <div class="col-2 d-none d-lg-block"></div>
                        <div class="col-lg-5 mt-5 mt-lg-0">
                            <div class="main_5_border_tapwill fs-4 lh-1">
                                <p class = 'tap fs-4'> TapWill</p>
                                <div class="line">
                                    <p> Автоматически загружает всю статистику по заказам и продажам, финансовые отчеты, операции и расходы - надежно и точно.</p>
                                </div>
                                <img src="../assets/images/main/tapwill.png" alt="">
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div><div id="tariff"></div>
            </main>
            
            <main class = 'main_6'>
                <div class="container">
                    <div class="row">
                    
                    <div class="col-lg-6">
                    <div class="tariff_block tariff_block_bg mb-5 mb-lg-0">
                        <p class="fs-1 fw-bold" style="color: #fff">Пробный период</p>
                        <p class="fs-1 fw-bold" style="color: #fff;">14 дней</p>
                        <a href="/reg" class="btn-gradient w-100 mx-auto mt-2"  style="font-size: 1.2em;">Подключить</a>
                    </div>
                    </div>
                    <div class="col-lg-6">
                    <div class="tariff_block mb-lg-0 py-lg-3">
                        <p class="fw-bold fs-1 mb-1">Рассчитай свой тариф</p>
                        <p class="fs-5 pb-2">Стоимость подписки на месяц, при суммарном обороте по заказам за 31 день:</p>
                        <ul class="nav nav-pills nav-fill">
                            <li class="nav-item my-1 my-lg-0">
                                <a class="nav-link" @click="tariffOption = 0; tariffPrice = 'Бесплатно'" :class="{active: tariffOption == 0}">менее 900 тыс.</a>
                            </li>
                            <li class="nav-item my-1 my-lg-0">
                                <a class="nav-link" @click="tariffOption = 1; tariffPrice = '990 руб./мес.'" :class="{active: tariffOption == 1}">900 тыс. - 1,5 млн.</a>
                            </li>
                            <li class="nav-item my-1 my-lg-0">
                                <a class="nav-link" @click="tariffOption = 2; tariffPrice = '2990 руб./мес.'" :class="{active: tariffOption == 2}">1,5 млн. - 3 млн.</a>
                            </li>
                            <li class="nav-item my-1 my-lg-0">
                                <a class="nav-link" @click="tariffOption = 3; tariffPrice = '4990 руб./мес.'" :class="{active: tariffOption == 3}">3 млн. - 5 млн.</a>
                            </li>
                            <li class="nav-item my-1 my-lg-0">
                                <a class="nav-link" @click="tariffOption = 4; tariffPrice = '9990 руб./мес.'" :class="{active: tariffOption == 4}">5 млн. - 10 млн.</a>
                            </li>
                            <li class="nav-item my-1 my-lg-0">
                                <a class="nav-link" @click="tariffOption = 5; tariffPrice = '19990 руб./мес.'" :class="{active: tariffOption == 5}">более 10 млн.</a>
                            </li>
                        </ul>
                        <!--<p class = 'fw-bold fs-4'>9 990 ₽/мес</p>-->
                        <p class = 'fw-bold fs-2 my-3'>{{ tariffPrice }}</p>
                        <a href="/reg" class="btn-gradient w-100 mx-auto" style="font-size: 1.2em;">Подключить </a>
                    </div>
                    </div>
                    <div id="faq"></div>
                    </div>

                <div class="header_FAQ mb-4" style="margin-top: 100px;" >
                    <p class = 'main_5_text fs-1 fw-bold mb-0'>FAQ</p>
                    <p class = 'main_5_text_grey fs-5'>Собрали ответы на часто задаваемые вопросы от вас</p>
                </div>
                <div class="main_6_cont">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="main_FAQ">
                                <button class = 'main_6_FAQ fs-4 fw-bolder' data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="false" aria-controls="faq1">Чем вы отличаетесь от других систем аналитики? <img src="../assets/images/main/plus.png" alt=""> </button>
                                <div class="collapse" id="faq1">
                                <div class="card card-body mb-3">
                                    Мы специализируемся на внутренней финансовой аналитике, поэтому данные у нас более точные и полезные. Кроме того наш сервис был создан и протестирован селлерами, основываясь на актуальных потребностях.
                                </div>
                                </div>

                                <button class = 'main_6_FAQ fs-4 fw-bolder' data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="false" aria-controls="faq2">Сколько я могу пользоваться своими данными? <img src="../assets/images/main/plus.png" alt=""> </button>
                                <div class="collapse" id="faq2">
                                <div class="card card-body mb-3">
                                    Вы можете пользоваться своими данными в любое время, пока у вас активен один из наших тарифов.
                                </div>
                                </div>

                                <button class = 'main_6_FAQ fs-4 fw-bolder' data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">Если я новичок и у меня еще мало товаров, мне подойдет ваш сервис? <img src="../assets/images/main/plus.png" alt=""> </button>
                                <div class="collapse" id="faq3">
                                <div class="card card-body mb-3">
                                    Оцифровка бизнес-процессов - насущная проблема на пути к построению успешного бизнеса, именно эту проблему помогает решить наш сервис. Если у вас возникают сомне ия о полезности сервиса для вашего бизнеса, то вы можете воспользоваться бесплатным 14-дневным доступам.                                
                                </div>
                                </div>

                                <button class = 'main_6_FAQ fs-4 fw-bolder' data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">Безопасно ли передавать вам необходимые API-ключи? <img src="../assets/images/main/plus.png" alt=""> </button>
                                <div class="collapse" id="faq4">
                                <div class="card card-body mb-3">
                                    Абсолютно. Забота о клиенте первостепенная задача нашего сервиса. Все данные надежно надежно хранятся на дата-центрах с особыми протоколами безопасности. А все сотрудники имеющие доступ к дата-центрам находятся под договором о неразглашении.                                
                                </div>
                                </div>

                                <button class = 'main_6_FAQ fs-4 fw-bolder' data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">У WildBerries регулярно выходят изменения API, как быстро выходят обновления в вашем сервисе? <img src="../assets/images/main/plus.png" alt=""> </button>
                                <div class="collapse" id="faq5">
                                <div class="card card-body mb-3">
                                    Мы регулярно в автоматизированном режиме следим за новостями маркетплейса, а команда IT-специалистов всегда готовы к любым изменениям.                                
                                </div>
                                </div>

                                <button class = 'main_6_FAQ fs-4 fw-bolder' data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="false" aria-controls="faq6">Столкнулся с ошибкой / Есть идея по улучшению функционала, как с вами связаться? <img src="../assets/images/main/plus.png" alt=""> </button>
                                <div class="collapse" id="faq6">
                                <div class="card card-body mb-3 d-block">
                                    Мы уважаем пользователей нашего сервиса и считаем, что для этого необходимо иметь с вами обратную связь. По любым интересующим вас вопросам вы можете обратиться на почту <a style="width: fit-content; display: inline;" href="mailto:mail@tapwill.ru">mail@tapwill.ru</a> или в бот  <a href="https://t.me/tapwill_support_bot" style="width: fit-content; display: inline;">@tapwill_support_bot</a>                               
                                </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="fourteen ">
                                <p class = 'main_6_FAQ_fourteen fs-4'> 14-дневный пробный период с неограниченным доступом ко всем возможностям</p>
                                <a href="/reg" class = 'btn-gradient'> Попробовать бесплатно </a>
                            </div>
                        </div>
                    </div>
                    
                    
                </div>
                </div>
            </main>
    
    </main>

    </div>
</main>
</template>

<style lang="scss" scoped>
@import '../assets/style/mainpage.scss';
</style>

<script>
export default{
    data(){
        return{
            showService: 1,
            tariffOption: 0,
            tariffPrice: 'Бесплатно'
        }
    }
}
</script>